import React, {useState, useEffect} from 'react';
import {Loader} from "semantic-ui-react";
import {HeaderPage, TableUsers, AddEditUserForm,} from "../../components/Admin";
import {ModalBasic} from "../../components/Common";
import {useUser} from "../../hooks";

export function UsersAdmin() {
    
  const [showModal, setShowModal] = useState(false)
  const [titleModal, setTitleModal] = useState(null)
  const [contentModal, setContentModal] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const {loading, users, getUsers, deleteUser } = useUser();
  

  console.log(users);

  useEffect(() => {
    getUsers();
  },[refetch]);

  const openCloseModal = () => setShowModal((prev) => !prev);
  const onRefetch = () => setRefetch((prev) => !prev);
 
  const addUser = () =>{
    setTitleModal("Nuevo Usuario");
    setContentModal(<AddEditUserForm onClose={openCloseModal} onRefetch = {onRefetch} />);
    openCloseModal();
  };

  const updateUser = (data) => {
    setTitleModal("Actualizar Usuario");
    setContentModal(
      <AddEditUserForm  
        onClose={openCloseModal} 
        onRefetch={onRefetch} 
        user={data}
      />);
    openCloseModal();
  };

  const onDeleteUser = async (data) =>{
    const result = window.confirm(`Estas Seguro de Eliminar el Usuario ? ${data.username}?`);

    if(result){
      try {
        await deleteUser(data.id);
        onRefetch();
      } catch (error) {
        console.error(error);
      }
     
    }
  };

  return (
    <div>
      <HeaderPage 
          title="Usuarios" 
          btnTitle = "Nuevo Usuario" 
          btnClick = {addUser}
      />

      {loading ? (
        <Loader active inline = "centered">
          Cargando...
        </Loader>
      ) : (
        <TableUsers 
          users = {users} 
          updateUser={updateUser} 
          onDeleteUser = {onDeleteUser}
          />
      )}
      <ModalBasic 
          show={showModal} 
          onClose={openCloseModal}
          title = {titleModal}
          children = {contentModal} />
    </div>
  );
  
}
