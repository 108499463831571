import React from 'react';
import portadaImg from './img/portada.png';

export function HomeAdmin() {
 
  return (
    <div>
      <img src={portadaImg}></img>
    </div>
  );
}
